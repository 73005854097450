@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
	font-family: "Russo";
	src: url("/public/fonts/RussoOne-Regular.ttf");
}
@font-face {
	font-family: "Yanone";
	src: url("/public/fonts/YanoneKaffeesatz-Medium.ttf");
}

.ant-menu-item::after {
	width: 16px;
	margin-inline: auto;
	justify-self: center;
	bottom: 20px !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
	color: #4d15ea;
}
